<template>
    <div class="add">
        <el-form
            ref="form"
            :model="form"
            style="padding-left:23px;flex:1; margin-top: 20px;"
            label-width="70px"
            label-position="left"
        >
            <el-form-item label="类型">
                <el-select
                    v-model="form.type"
                    size="small"
                    style="width:270px;"
                    placeholder="请选择工单分类"
                    clearable
                    :disabled="!disabled"
                >
                    <el-option label="常见问题" value="1"></el-option>
                    <el-option label="常用语" value="2"></el-option>
                    <el-option label="常用链接" value="3"></el-option>
                    <el-option label="附件库" value="4"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标题">
                <el-input
                    v-model="form.title"
                    placeholder="请输入标题"
                    size="small"
                    style="width:270px;"
                    :disabled="!disabled"
                ></el-input>
            </el-form-item>
            <el-form-item label="内容">
                <editor
                    ref="editorOne"
                    style="width:350px"
                    v-model="detail"
                    @change="change"
                ></editor>
            </el-form-item>
        </el-form>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                v-if="disabled"
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import Editor from './wangEditor';
import { knowledgeEdit } from '@/api/cs/knowledge.js';
export default {
    components: {
        Editor,
    },
    data() {
        return {
            form: {},
            detail: '',
            val: '',
            disabled: false,
        };
    },
    methods: {
        getData(row, type) {
            // console.log(row);
            this.form = row;
            this.form.type = String(row.type);
            this.detail = row.content;
            this.disabled = type;
        },
        change(val) {
            this.val = val;
            // console.log(val);
        },
        onSubmit() {
            if (!this.form.type) {
                return this.$message.error('请选择类型');
            }
            if (!this.form.title) {
                return this.$message.error('请输入标题');
            }
            if (!this.val) {
                return this.$message.error('请输入内用');
            }
            let data = {
                param: {
                    type: this.form.type,
                    title: this.form.title,
                    content: this.val,
                    id: this.form.id,
                },
            };
            knowledgeEdit(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$message.success('修改成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.add {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
