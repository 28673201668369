<template>
    <div class="knowledge">
        <div class="knowledge_list">
            <p class="title">
                <span></span>
                知识库类型
            </p>
            <div class="typeList">
                <div
                    v-for="(item, index) in typeList"
                    :class="index + 1 == isIndex ? 'isColor' : ''"
                    @click="onSwitch(index + 1)"
                >
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div class="table">
            <div class="title">
                <p>
                    <span></span>
                    知识库类型
                </p>
                <span></span>
                <button @click="onAdd()" v-if="btnP.upData">新建内容</button>
            </div>
            <el-table
                class="table-box"
                ref="multipleTable"
                :data="tableData"
                height="100%"
                :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#333333',
                    'font-size': '14px',
                    'font-weight': '500',
                }"
            >
                <el-table-column width="10"> </el-table-column>

                <el-table-column
                    label="标题"
                    prop="title"
                    min-width="120"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            style="color: #2370eb"
                            @click="onDetails(scope.row)"
                            >{{ scope.row.title }}</el-button
                        >
                    </template>
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column
                    label="最后编辑人"
                    prop="editAdminName"
                    show-overflow-tooltip
                    width="100"
                >
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column
                    label="最后更新时间"
                    show-overflow-tooltip
                    width="93"
                >
                    <template slot-scope="scope">
                        <span>{{
                            $tableDataHandle.createTime({
                                createTime: scope.row.createTime,
                            })
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column label="操作" fixed="right" width="120">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="samll"
                            style="color: #2370eb"
                            @click="onDetele(scope.row)"
                            >删除</el-button
                        >
                        <el-button
                            type="text"
                            size="samll"
                            style="color: #2370eb"
                            @click="onGeneration(scope.row)"
                            >生成</el-button
                        >
                    </template>
                </el-table-column>
                <div slot="empty" class="empty">
                    <img src="@/assets/zanwushuju.png" />
                    <span style="display: block">暂无数据～</span>
                </div>
            </el-table>
            <div class="page-box">
                <el-pagination
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    :current-page.sync="currentPage"
                    :page-size="pagesize"
                    :page-sizes="[10, 20, 50]"
                    layout="total,sizes,prev, pager, next, jumper"
                    :total="total"
                ></el-pagination>
            </div>
        </div>
        <!-- 新建 -->
        <el-drawer
            :visible.sync="drawerAdd"
            :direction="direction"
            :before-close="handleAdd"
            size="480px"
        >
            <template slot="title">
                <div style="display: flex; align-items: center">新增内容</div>
            </template>
            <Add @close="handleAdd" ref="add" />
        </el-drawer>
        <!-- 详情、编辑 -->
        <el-drawer
            :visible.sync="drawerDetails"
            :direction="direction"
            :before-close="handleDetails"
            size="480px"
        >
            <template slot="title">
                <div style="display: flex; align-items: center">
                    {{ drawerTitle }}
                </div>
            </template>
            <Details @close="handleDetails" ref="details" />
        </el-drawer>
        <!--  删除 -->
        <el-dialog
            :visible.sync="drawerDelete"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    删除
                </div>
            </template>

            <div class="delete">是否删除该条问题？</div>
            <div class="bottom-but">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                    "
                    @click="handleClose"
                    >取消</el-button
                >
                <el-button
                    round
                    @click="onSubmit"
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 25px;
                    "
                    size="mini"
                    >确认</el-button
                >
            </div>
        </el-dialog>
        <!--  生成 -->
        <el-dialog
            :visible.sync="drawerGenerate"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    文件地址
                </div>
            </template>

            <div class="delete">
                {{ address }}
                <span
                    @click="copyText(address)"
                    style="margin-left: 20px; color: #2370eb; cursor: pointer"
                    >复制</span
                >
            </div>
            <div class="bottom-but">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                        margin-right: 20px;
                    "
                    @click="handleClose"
                    >取消</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    knowledgeList,
    knowledgeDelete,
    makeHtml,
} from '@/api/cs/knowledge.js';
import Add from './addKnowledge.vue';
import Details from './details.vue';
export default {
    components: {
        Add,
        Details,
    },
    data() {
        return {
            data: {},
            btnP: {},
            typeList: [
                {
                    name: '常见问题',
                },
                {
                    name: '常用语',
                },
                {
                    name: '常用链接',
                },
                {
                    name: '附件库',
                },
            ],
            isIndex: 1,
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            drawerAdd: false,
            direction: 'rtl',
            drawerTitle: '',
            drawerDetails: false,
            drawerDelete: false,
            drawerGenerate: false,
            address: '',
        };
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;

            this.getData();
        },
        onSwitch(i) {
            this.isIndex = i;
            this.getData();
        },
        getData() {
            let data = {
                param: {
                    type: this.isIndex,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            knowledgeList(data).then((res) => {
                this.tableData = res.data.list;
                this.total = res.data.total;
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        onAdd() {
            this.drawerAdd = true;
        },
        handleAdd() {
            this.drawerAdd = false;
            this.getData();
        },
        onDetails(row) {
            let btn = JSON.parse(sessionStorage.getItem('isButArr'));
            let type = false;
            var btnChild = [];
            for (let i in btn) {
                if (btn[i].route == 'upData') {
                    type = true;
                    break;
                }
            }
            if (type) {
                this.drawerTitle = '编辑内容';
                for (let i in btn) {
                    if (btn[i].route == 'upData') {
                        btnChild = btn[i].children;
                    }
                }
            } else {
                this.drawerTitle = '问题详情';
            }
            this.drawerDetails = true;
            let btnP = {};
            for (let i in btnChild) {
                btnP['' + btnChild[i].route] = true;
            }
            // console.log(btnP);
            setTimeout(() => {
                this.$refs.details.getData(row, type);
            }, 0);
            this.$formDetails.commit('detailsData', JSON.stringify(row));
            this.$choiceLabel.commit('getIsDetails', type);
            this.$choiceLabel.commit('getType', '2');
        },
        handleDetails() {
            this.drawerDetails = false;
            this.getData();
        },
        onDetele(row) {
            this.data = row;
            this.drawerDelete = true;
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.data.id,
                },
            };
            knowledgeDelete(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        handleClose() {
            this.drawerDelete = false;
            this.drawerGenerate = false;
            this.getData();
        },
        onGeneration(row) {
            let data = {
                param: {
                    id: row.id,
                },
            };
            makeHtml(data).then((res) => {
                if (res.code == 200) {
                    // console.log(res);
                    this.drawerGenerate = true;
                    this.address =
                        'http://file.ijasoso.com/html/' + row.id + '.html';
                    this.$message.success('操作成功');
                }
            });
        },
        //复制
        copyText(data) {
            let url = data;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.knowledge {
    width: 100%;
    height: 100%;
    display: flex;

    .knowledge_list {
        width: 280px;
        background: #fff;
        height: 100%;
        border-radius: 5px 5px 0 0;
        display: flex;
        flex-direction: column;
        .title {
            display: flex;
            align-items: center;
            padding: 15px 0;
            font-size: 14px;
            border-bottom: 1px dashed #e5e5e5;

            span {
                width: 2px;
                height: 16px;
                background: #2370eb;
                border-radius: 1px 1px 0px 0px;
                margin-right: 20px;
            }
        }
        .typeList {
            flex: 1;
            div {
                line-height: 40px;
                margin: 16px 27px 0 13px;
                padding-left: 10px;
                cursor: pointer;
                font-size: 14px;
            }
            .isColor {
                background: #f3f5f7;
                border-radius: 2px;
            }
        }
    }
    .table {
        flex: 1;
        background: #fff;
        border-radius: 5px 5px 0 0;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        .title {
            display: flex;
            align-items: center;
            padding: 15px 0;
            font-size: 14px;
            border-bottom: 1px dashed #e5e5e5;
            flex-direction: row;
            justify-content: space-between;
            p {
                display: flex;
                align-items: center;
                span {
                    width: 2px;
                    height: 16px;
                    background: #2370eb;
                    border-radius: 1px 1px 0px 0px;
                    margin-right: 20px;
                }
            }
            .add {
                border-color: #d9d9d9;
                color: #333333;
            }
            button {
                font-size: 12px;
                padding: 0 7px 0 12px;
                height: 24px;
                background: #ffffff;
                border-radius: 2px;
                border: 1px solid #2370eb;
                cursor: pointer;
                color: #2370eb;
                margin-right: 16px;
            }
        }
    }
    .delete {
        padding: 100px 0;
        text-align: center;
        font-size: 16px;
        color: #333;
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        margin-top: 16px;
        text-align: right;
        button {
            width: 90px;
        }
    }
}
.page-box {
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
