import http from '@/utils/http.js'

// 列表
export function knowledgeList(data) {
    return http({
        url: "/jasoboss/knowledge/list",
        method: "post",
        data
    })
}
// 添加
export function knowledgeAdd(data) {
    return http({
        url: "/jasoboss/knowledge/add",
        method: "post",
        data
    })
}
// 修改
export function knowledgeEdit(data) {
    return http({
        url: "/jasoboss/knowledge/update",
        method: "post",
        data
    })
}
// 删除
export function knowledgeDelete(data) {
    return http({
        url: "/jasoboss/knowledge/delete",
        method: "post",
        data
    })
}
// 生成
export function makeHtml(data) {
    return http({
        url: "/jasoboss/knowledge/make/html",
        method: "post",
        data
    })
}